.interest-rates {
  width: 100%;
  display: block;
  border-spacing: 0;

  &__best {
    @media (min-width: $min-tablet) {
      box-shadow: 0 8px 20px rgba($c-dark-blue, 0.1);
    }
  }

  &__percentage {
    font-weight: 400;
  }

  &__logo {
    max-height: 41px;
    max-width: 120px;
    width: auto;
    height: auto;

    @media (max-width: $max-mobile) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  thead {
    display: block;
    border-bottom: solid 1px #d8d8d8;

    .button:not(.active):not(:hover) {
      border: solid 1px rgba(#0c0762, .1);
      background-color: rgba(17, 0, 102, 0.03);
    }

    tr {
      display: flex;
      align-items: center;

      @media (max-width: $max-mobile) {
        display: block;
      }
    }
  }

  th {
    &:first-child {
      width: 340px;
      text-align: left;

      @media (max-width: 920px) {
        width: 237px;
      }

      @media (max-width: $max-mobile) {
        display: block;
        width: 100%;
        margin-bottom: .5em;
      }
    }
  }

  tbody:not(.glide__slides) {
    display: block;
  }

  tbody.glide__slides {
    @media (min-width: $min-tablet) {
      display: block;
    }
  }

  tbody tr {
    display: flex;
    padding: 12px 20px;
    align-items: center;
    border: solid 1px rgba(12, 7, 98, 0.1);
  }

  tr + tr {
    @media (min-width: $min-tablet) {
      margin-top: 15px;
    }
  }

  td {
    &.active {
      background: $c-yellow;
      border-radius: 100px;
      padding: 3px 10px;
    }
  }

  &--lg-alt {
    border-top: solid 1px #d8d8d8;
    
    th {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: calc((100% - 560px) / 5);

        @media (max-width: 920px) {
          width: calc((100% - 370px) / 5);
        }

        @media (max-width: $max-mobile) {
          display: inline-block;
          width: auto;
        }
      }

      &:nth-child(7) {
        width: 220px;
        text-align: right;

        @media (max-width: 920px) {
          width: 140px;
        }

        @media (max-width: $max-mobile) {
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }

    thead .button {
      @media (min-width: $min-tablet) {
        background: none !important;
        border: none !important;
        cursor: initial !important;
        color: $c-dark-blue !important;
      }
    }

    td.active {
      background: transparent;
    }

    tbody tr {
      justify-content: space-between;

      @media (max-width: $max-mobile) {
        display: block;
        padding: 18px 12px 12px;
      }

      td {
        @media (max-width: $max-mobile) {
          text-align: center;
          display: block;
        }
      }
    }

    tr + tr {
      @media (max-width: $max-mobile) {
        margin-top: 16px;
      }
    }

    tbody tr:not(.ad) td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: calc((100% - 520px) / 5);
        text-align: center;

        @media (max-width: 920px) {
          width: calc((100% - 337px) / 5);
        }

        @media (max-width: $max-mobile) {
          width: 100%;

          &:not(.active) {
            display: none;
          }
        }
      }

      &.active {
        @media (max-width: $max-mobile) {
          background: none;
          text-align: center;
          font-size: 24px;
          width: 100%;
          font-weight: 600;
          margin-bottom: .5em;
          margin-top: .25em;
          padding: 0;
        }
      }
    }

    tr:not(.ad) td {
      &:first-child {
        width: 320px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 920px) {
          width: 217px;
        }

        @media (max-width: $max-mobile) {
          width: 140px;
          height: 41px;
          margin: 0 auto 5px;
          position: relative;
          text-align: center;
        }
      }

      &:nth-child(7) {
        width: 200px;
        text-align: right;

        @media (max-width: 920px) {
          width: 120px;

          .button {
            padding: 15px;
          }
        }

        @media (max-width: $max-mobile) {
          width: 100%;
          text-align: center;
          clear: both;
        }
      }
    }
  }

  &--lg {
    th {
      &:nth-child(2) {
        width: 0;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: calc((100% - 560px) / 5);

        @media (max-width: 920px) {
          width: calc((100% - 370px) / 5);
        }

        @media (max-width: $max-mobile) {
          display: inline-block;
          width: auto;
        }
      }

      &:nth-child(8) {
        width: 220px;
        text-align: right;

        @media (max-width: 920px) {
          width: 140px;
        }

        @media (max-width: $max-mobile) {
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }

    .interest-rates__logo {
      vertical-align: middle;

      @media (max-width: 920px) {
        max-width: 90px;
      }

      @media (max-width: $max-mobile) {
        max-height: 41px;
        max-width: 120px;
        width: auto;
        height: auto;
      }
    }

    tbody tr {
      justify-content: space-between;

      @media (max-width: $max-mobile) {
        display: block;
        padding: 18px 12px 12px;
      }

      td {
        @media (max-width: $max-mobile) {
          text-align: center;
          display: block;
        }
      }
    }

    tr + tr {
      @media (max-width: $max-mobile) {
        margin-top: 16px;
      }
    }

    tbody tr:not(.ad) td {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: calc((100% - 520px) / 5);
        text-align: center;

        @media (max-width: 920px) {
          width: calc((100% - 337px) / 5);
        }

        @media (max-width: $max-mobile) {
          width: 100%;

          &:not(.active) {
            display: none;
          }
        }
      }

      &.active {
        @media (max-width: $max-mobile) {
          background: none;
          text-align: center;
          font-size: 24px;
          width: 100%;
          font-weight: 600;
          margin-bottom: .5em;
          margin-top: .25em;
          padding: 0;
        }
      }
    }

    tr:not(.ad) td {
      &:first-child {
        width: 140px;

        @media (max-width: 920px) {
          width: 100px;
        }

        @media (max-width: $max-mobile) {
          width: 140px;
          height: 41px;
          margin: 0 auto 5px;
          position: relative;
          text-align: center;
        }
      }

      &:nth-child(2) {
        text-align: left;
        width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 920px) {
          width: 117px;
        }

        @media (max-width: $max-mobile) {
          width: 100%;
          overflow: hidden;
          text-align: center;
        }
      }

      &:nth-child(8) {
        width: 200px;
        text-align: right;

        @media (max-width: 920px) {
          width: 120px;

          .button {
            padding: 15px;
          }
        }

        @media (max-width: $max-mobile) {
          width: 100%;
          text-align: center;
          clear: both;
        }
      }
    }
  }

  &--home {
    .interest-rates__logo {
      vertical-align: middle;

      @media (max-width: 920px) {
        max-width: 90px;
      }

      @media (max-width: $max-mobile) {
        max-height: 41px;
        max-width: 120px;
        width: auto;
        height: auto;
      }
    }

    thead {
      th:first-child {
        width: 192px;

        @media (min-width: 921px) {
          width: 227px;
        }
      }

      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(6) {
        @media (min-width: $min-tablet) {
          width: calc((100% - 340px) / 5);
        }

        @media (min-width: 921px) {
          width: calc((100% - 460px) / 5);
        }
      }
    }

    tbody tr {
      justify-content: space-between;

      @media (max-width: $max-mobile) {
        display: block;
        padding: 18px 12px 12px;
      }

      td {
        @media (max-width: $max-mobile) {
          text-align: center;
          display: block;
        }
      }
    }

    tr + tr {
      @media (max-width: $max-mobile) {
        margin-top: 16px;
      }
    }

    tbody tr:not(.ad) td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: calc((100% - 520px) / 5);
        text-align: center;

        @media (max-width: 920px) {
          width: calc((100% - 420px) / 5);
        }

        @media (max-width: $max-mobile) {
          width: 100%;

          &:not(.active) {
            display: none;
          }
        }
      }

      &.active {
        @media (max-width: $max-mobile) {
          background: none;
          text-align: center;
          font-size: 24px;
          width: 100%;
          font-weight: 600;
          margin-bottom: .5em;
          margin-top: .25em;
          padding: 0;
        }
      }
    }

    tr:not(.ad) td {
      &:first-child {
        width: 200px;

        @media (max-width: 920px) {
          width: 160px;
        }

        @media (max-width: $max-mobile) {
          width: 140px;
          height: 41px;
          margin: 0 auto 5px;
          position: relative;
          text-align: center;
        }
      }

      &:nth-child(7) {
        width: 200px;
        text-align: right;

        @media (max-width: 920px) {
          width: 120px;

          .button {
            padding: 15px;
          }
        }

        @media (max-width: $max-mobile) {
          width: 100%;
          text-align: center;
          clear: both;
        }
      }
    }
  }

  &--sm-alt {
    tbody tr {
      @media (max-width: $max-mobile) {
        display: block;
        width: 176px;
        padding: 18px 12px 12px;
        margin-right: 16px;
      }
    }

    td {
      @media (max-width: $max-mobile) {
        text-align: center;
        display: block;
      }

      &:first-child {
        font-size: 0;
        width: 150px;
        flex-shrink: 1;
        display: block;

        @media (min-width: $min-tablet) and (max-width: $max-tablet) {
          width: 100px;

          .interest-rates__logo {
            max-width: 100px;
          }
        }

        @media (max-width: $max-mobile) {
          width: 140px;
          height: 41px;
          margin: 0 auto 5px;
          position: relative;
        }
      }

      &:nth-child(2) {
        display: none;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (min-width: $min-desktop) {
          display: none;
        }

        @media (max-width: $max-mobile) {
          display: block;
          width: 100%;
          height: 28px;
        }
      }

      &:nth-child(3) {
        width: 85px;
        font-size: 24px;
        font-weight: 600;
        width: calc(100% - 215px);
        text-align: center;

        @media (min-width: $min-tablet) and (max-width: $max-tablet) {
          width: calc(100% - 205px);
        }

        @media (min-width: $min-desktop) {
          width: calc(100% - 340px);
        }

        @media (max-width: $max-mobile) {
          width: 100%;
          margin-bottom: .5em;
          margin-top: .25em;
          padding: 0;
        }
      }

      &:last-child {
        width: 105px;

        @media (min-width: $min-desktop) {
          width: 190px;
        }

        @media (max-width: $max-mobile) {
          width: 100%;
        }
      }
    }
  }

  &--sm {
    tbody tr {
      @media (max-width: $max-mobile) {
        display: block;
        width: 176px;
        margin-right: 16px;
        padding: 18px 12px 12px;
      }
    }

    td {
      @media (max-width: $max-mobile) {
        text-align: center;
        display: block;
      }

      &:first-child {
        font-size: 0;
        width: 130px;
        flex-shrink: 1;
        display: block;

        @media (max-width: $max-mobile) {
          width: 140px;
          height: 41px;
          margin: 0 auto 5px;
          position: relative;
        }
      }

      &:nth-child(2) {
        width: calc(100% - 345px);
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: $max-mobile) {
          width: 100%;
        }
      }

      &:nth-child(3) {
        width: 85px;
        font-size: 24px;
        font-weight: 600;

        @media (max-width: $max-mobile) {
          background: none;
          text-align: center;
          width: 100%;
          margin-bottom: .5em;
          margin-top: .25em;
          padding: 0;
        }
      }

      &:last-child {
        width: 90px;

        @media (max-width: $max-mobile) {
          width: 100%;
        }
      }
    }
  }
}

.ad {
  background: $c-yellow;
  padding: 25px !important;

  td {
    flex-basis: auto !important;
  }

  td + td {
    @media (max-width: $max-mobile) {
      margin-top: .5em;
    }
  }
}