.inline-form {
  padding: 12px;

  @media (max-width: $max-mobile) {
    padding: 20px 12px;
  }

  &__icon + label {
    @media (min-width: $min-tablet) {
      margin-left: 20px;
    }
  }

  &__col {
    padding-left: 12px;
    padding-right: 12px;

    @media (max-width: $max-mobile) {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--label {
      @media (max-width: $max-mobile) {
        margin-bottom: 4px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--icon {
      flex-shrink: 0;
    }

    &--lg {
      width: 39%;
    }

    &--sm {
      width: 22%;
    }

    &--address {
      width: 296px;
      flex-grow: 1;
    }

    &--price {
      flex-shrink: 1;
    }

    @media (max-width: $max-mobile) {
      width: 100%;
    }
  }

  &__stroke {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;

      @media (min-width: $min-tablet) {
        right: 12.5px;
        border-right: 1px solid #D8D8D8;
        top: 0;
      }

      @media (max-width: $max-mobile) {
        left: 8px;
        right: 8px;
        border-bottom: 1px solid #D8D8D8;
      }
    }
  }

  &__input {
    appearance: none;
    border: 1px solid #d8d8d8;
    padding: 17px;
    display: block;
    width: 100%;
    border-radius: 8px;
    text-overflow: ellipsis;

    @media (max-width: $max-mobile) {
      border: none;
      border-bottom: 1px solid #d8d8d8;
      padding: 10px 0;
      border-radius: 0;
    }

    &--prepended {
      padding-left: 42px;

      @media (max-width: $max-mobile) {
        padding-left: 22px;
      }
    }

    &--box {
      @media (max-width: $max-mobile) {
        border: 1px solid #d8d8d8;
        padding: 10px;
        border-radius: 8px;

        &.inline-form__input--prepended {
          padding-left: 32px;
        }
      }
    }
  }

  select {
    appearance: none;
    background-color: transparent;
    background-image: url(../images/select-chevron.svg);
    background-repeat: no-repeat;
    background-position: right 12px top 25px;
    background-size: 12px 6px;
    padding-right: 32px;

    @media (max-width: $max-mobile) {
      background-position: right top 21px;
    }
  }

  .toggle {
    margin-top: 6px;
  }
}

.list-form {
  &__col {
    @extend %col-tablet-12;
    @extend %col-desktop-6;
  }

  &__col + &__col {
    @media (max-width: $max-tablet) {
      margin-top: 1em;
    }
  }

  &__box {
    border: solid 1px rgba(12, 7, 98, 0.1);
  }

  &__box + &__box {
    margin-top: 20px;
  }

  &__row {
    padding: 21px 34px; // 21px ​48px
  }

  &__row + &__row,
  &__border {
    border-top: solid 1px #f0f0f0;
  }

  &__figure {
    width: 70px;
    height: 70px;
    float: left;
  }

  &__figure + &__entry {
    @media (min-width: $min-tablet) {
      padding-left: 107px;
    }
  }

  &__entry {
    padding-left: 20px;
    padding-right: 20px;

    &--inline {
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        margin-bottom: 0;
      }
    }
  }

  &__error {
    margin: 0.5em 0 0;
    font-size: 13px;
    color: red;
    display: none;
  }

  .has-error {
    .list-form__error {
      display: block;
    }

    .list-form__regular-input {
      border-color: red;
    }
  }

  &__label {
    display: block;
    font-weight: bold;
  }

  &__regular-input {
    appearance: none;
    padding: 14px 18px;
    border: 1px solid $c-light;
    border-radius: 8px;
    display: block;
    width: 100%;
    color: $c-dark-blue;

    &--box {
      background-color: white;
      border-radius: 100px;
      display: inline-block;
      padding: 15px 40px 15px 24px;
      background-position: right 20px center;
    }

    &--prepend {
      padding-left: 36px;
    }
  }

  &__prepend {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: 18px;
      line-height: 1.15;
    }

    &--euro::before {
      content: '€';
    }

    &--inline::before {
      top: 17px;
      left: 21px;

      @media (max-width: $max-mobile) {
        top: 10px;
        left: 0;
      }
    }

    &--box::before {
      @media (max-width: $max-mobile) {
        left: 10px;
      }
    }
  }
}

select {
  background-image: url(~/assets/images/select-chevron.svg);
  background-size: 10.121px 5.811px;
  background-position: right 10px center;
  background-repeat: no-repeat;
}

.toggle {
  appearance: none;
  position: relative;
  background: #D9D9D9;
  width: 43px;
  height: 25px;
  border-radius: 100px;
  cursor: pointer;
  flex-shrink: 0;

  &::before {
    content: '';
    background: white;
    width: 21px;
    height: 21px;
    top: 2px;
    left: 2px;
    border-radius: 100px;
    position: absolute;
    transition: transform .3s var(--ease-in-out-expo);
    will-change: transform;
  }

  &:checked {
    background: $c-blue;

    &::before {
      transform: translateX(18px);
    }
  }
}

.single-checkbox {
  margin-top: 16px;

  &__label {
    background-color: white;
    border-radius: 100px;
    display: inline-block;
    padding: 15px 24px;
    line-height: 1;
    cursor: pointer;
  }

  &__checkbox {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: -4px;
    cursor: pointer;
    background: none;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &::before {
      background: $c-gray;
      z-index: 1;
    }

    &::after {
      background-image: url(~/assets/images/checkbox.svg);
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 2;
      opacity: 0;
    }

    &:checked {
      &::after {
        opacity: 1;
      }
    }
  }

  &__check {
    //
  }
}

.inline-numbers-group {
  &__wrap {
    @media (min-width: $min-tablet) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    margin: 0;

    @media (max-width: $max-mobile) {
      margin-bottom: .5em;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: $max-mobile) {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    @media (min-width: $min-tablet) {
      display: flex;
      flex: 1 0 auto;
      gap: 0 16px;
    }

    li {
      display: inline-block;

      @media (max-width: $max-mobile) {
        margin-bottom: 5px;
        margin-right: 2px;
      }
    }

    &--alt {
      justify-content: space-evenly;
    }
  }

  &__expl {
    margin-left: 30px;
    
    @media (max-width: 920px) {
      margin-left: 0;
    }

    @media (max-width: $max-mobile) {
      margin-top: .5em;
      display: block;
    }
  }

  &--sm {
    &__title {
      @media (min-width: $min-tablet) {
        width: calc(192px + ((100% - 340px) / 5 - 58px) / 2)
      }

      @media (min-width: 921px) {
        width: calc(227px + ((100% - 460px) / 5 - 58px) / 2);
      }
    }

    .inline-numbers-group__list {
      @media (min-width: $min-desktop) {
        gap: 0 32px;
      }
    }

    .button {
      min-width: 42px;

      @media (min-width: 370px) {
        min-width: 40px;
      }

      @media (min-width: $min-tablet) {
        min-width: 42px;
      }
    }
  }

  &--lg {
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;

    &__title {
      @media (min-width: $min-tablet) {
        margin-right: 30px;
      }
    }
    
    @media (max-width: $max-mobile) {
      .inline-numbers-group__list li {
        margin-right: -1px;
      }
    }

    @media (min-width: $min-tablet) {
      .inline-numbers-group__list {
        gap: 4px;
        flex-wrap: wrap;
        flex: 0 1 100%;
      }
    }

    @media (min-width: $min-desktop) {
      .inline-numbers-group__list {
        justify-content: space-between;
        gap: 0 14px;
      }
    }
  }
}

.inline-numbers-group + .inline-numbers-group {
  margin-top: 15px;
}

.inline-numbers-group--lg + .inline-numbers-group--lg {
  margin-top: 0;
  border-top: none;
}

form {
  label {
    font-weight: bold;
    margin-bottom: 4px;

    &[for] {
      cursor: pointer;
    }
  }
}

.radio-line {
  padding: 17px 34px;
  display: flex;
  align-items: center;

  &__image {
    margin-right: 35px;
    flex-shrink: 1;
  }

  &__entry {
    flex-grow: 1;
  }

  &__radio {
    display: none;
  }

  &__text {
    display: block;
    position: relative;
    padding-right: 34px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    &::before {
      background: $c-dark-blue;
      opacity: 0;
      transform: scale(0);
      width: 16px;
      height: 16px;
      right: 4px;
    }

    &::after {
      border: 1px solid #D8D8D8;
    }
  }

  &__radio:checked + &__text {
    &::before {
      transform: translateY(-50%) scale(1);
      opacity: 1;
    }
  }
}
