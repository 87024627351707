[data-slide-mortgages-container] {
  overflow: hidden;
  position: relative;
  transition: max-height 600ms ease-out;
  will-change: max-height;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 10;
    background: linear-gradient(to top, $c-light, rgba($c-light, 0));
    height: 150px;
    opacity: 0;
    transition: opacity 400ms ease-out;
    will-change: opacity;
  }

  &.show-limited {
    max-height: 400px;

    @media (max-width: $max-mobile) {
      max-height: 1020px;
    }

    &::after {
      opacity: 1;
    }
  }
}